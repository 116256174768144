/** @jsx jsx */
import { jsx } from '@emotion/core';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import '../App.css';

const animatedComponents = makeAnimated();

const colourStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 0,
        borderColor: '#f5f5f5',
        borderBottom: '1px solid #8284e5',
        height: 45,
        backgroundColor: '#f5f5f5'
    }),

    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
            ...styles,
            display: data.isDeleted ? 'none' : '',
            ...dot(data.model_suggested, isFocused, isSelected)
        };
    },


    multiValueRemove: (provided) => {
        return {
            ...provided,
            cursor: 'pointer'
        };
    },

};
const dot = (predicted: boolean, isFocused: boolean, isSelected: boolean) => {
    return predicted
        ? {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            ':after': {
                backgroundColor: isSelected ? 'white' : 'green',
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginLeft: 8,
                height: 10,
                width: 10
            }
        }
        : {};
};

const Dropdown = ({ data, sectionIndex, questionIndex, state, handleChange, disableField, updateLocalState }) => {

    const dataState = Array.isArray(state)
        ? state.length > 0
            ? state[sectionIndex] && state[sectionIndex][data.question]
            : []
        : null;
    
    const getOptions = (selectedData, name) => {
        let options: any[] = [
            {
                value: '',
                label: (
                    <div data-toggle="tooltip" data-placement="top" title="Select" id="select">
                        Select
                    </div>
                ),
                id: '',
                isDeleted: false,
                name: name,
                title: '',
                hasAnswer: ''
            }
        ];
         // Remove duplicates by filtering based on 'value and is_deleted'
         selectedData =  selectedData.filter((item, index, self) =>
            index === self.findIndex((t) => t.value === item.value && !t.is_deleted)
          );
        selectedData.map((list, i) => {
            let exactQuestion = list.value;
            let status = (state.isEdit === true && (list.is_deleted && list.value === data?.answer?.toString())) ? '( This option is no longer available. ) ' : ''
            options.push({
                value: list.id,
                label: (
                    <div
                        style={{
                            display: state.isEdit === true && list.is_deleted ? list.value === data?.answer?.toString() ? '' : 'none' : ''
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        id={`${list.value} - ${name}`}
                    >
                        {exactQuestion} {status && <span style={{ color: '#f45050', fontWeight: 'bold' }}>{status}</span>}
                    </div>
                ),
                id: list.id,
                isDeleted: list.is_deleted,
                name: name,
                title: list.value,
                hasAnswer: data?.answer?.toString(),
                model_suggested: list?.model_suggested ? list?.model_suggested : false,
                isChecked: list?.model_suggested ? list?.model_suggested : false,
            });
        });
        
        return options;
    };

    const customFilterOption = (option, inputValue) => {
        return option.label?.props?.children[0]?.toLowerCase().includes(inputValue?.toLowerCase());
    };

    return (
        <Select
            key={questionIndex}
            value={getOptions(data.suggested_answers, data.question).filter(
                (value) =>
                (dataState?.toString() ? dataState === value.title || dataState === value.id : data.answer?.toString() === value.title || data.answer?.value === value.title || data.answer?.value === value.id)
            )}
            styles={colourStyles}
            name={data.question}
            components={animatedComponents}
            options={getOptions(data.suggested_answers, data.question)}
            className="my-custom-select"
            placeholder="Select"
            onChange={(e) => handleChange(e, data, sectionIndex, questionIndex, state, updateLocalState, '')}
            isSearchable={true}
            filterOption={customFilterOption}
            id={data.question}
            isDisabled={disableField}
        />
    );
};

export default Dropdown;
