/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { parentFieldRow,childTwoCol, childLabel, label2 } from '../styles';
import Dropdown from '../../Common/DropdownComponent';
import Radio from '../../Common/RadioOptionComponent';
import Checkbox from '../../Common/CheckboxComponent';
import NumberField from '../../Common/NumberComponent';
import DateField from '../../Common/DateComponent';
import InputField from '../../Common/InputComponent';
import * as Styles from './FamilyDeatilsStyleComponent';
import * as Utility from "../../Common/Base";
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import { style } from '../FamilySearchComponent/IFamilySearch.Interface';

const DynamicGeneratedQuestions = ({
    DynamicQuestions,
    QuestionsData,
    state,
    validateDateFormate,
    index,
    setChildAnswerstoParent,
    questionIndex,
    isSubmitted,
    configType
}) => {
    const [generatedQuestions, setGeneratedQuestions] = useState([] as string[] | any);
    const [repeatedQuestions, setRepeatedQuestions] = useState([] as string[] | any);


    useEffect(() => {
        let list1 = DynamicQuestions[index]?.questions.find((ques) => ques.question === QuestionsData[0].question);
        let list =
            list1.child_questions.length > 0 &&
                list1.child_questions.some((item) => item.answer === QuestionsData[0].answer?.value?.toString())
                ? DynamicQuestions[index]?.questions.filter((ques) =>
                    list1.child_questions[0].questions.includes(ques.question)
                )
                : [];

        let listQuestions: any = [];
        let loopRotations =
            QuestionsData[0]?.answer?.repeated?.length === 0 ? 1 : QuestionsData[0]?.answer?.repeated?.length;
        for (let i = 1; i <= loopRotations; i++) {
            if (loopRotations === 1) {
                listQuestions = [list];
            } else {
                listQuestions.push(list);
            }
        }
        // Extract the 'question' values from list1 to an array for easy lookup
        const list1Questions = list.map((item) => item.question);

        if (list1.answer && list1.answer.repeated && list1.answer.repeated.length > 0) {
            // Filter list based on the 'question' field presence in list1Questions
            list1.answer.repeated = list1.answer.repeated.map((item) => {
                const newItem = {};
                Object.keys(item).forEach((key) => {
                    if (list1Questions.includes(key)) {
                        let answerValue = list.find((ques) => ques.question === key )?.suggested_answers.filter((sug_ans)=> sug_ans.value === item[key])

                        newItem[key] = answerValue.length > 0 ? answerValue[0]?.id : item[key];
                    }
                });
                return newItem;
            });
        }

        setRepeatedQuestions(list1.answer);
        setGeneratedQuestions((prevState) =>
            list.length > 0 ? (prevState.length > 0 ? [...prevState] : [...prevState, ...listQuestions]) : []
        );
    }, [state.client_form]);

    const handlerChange = async (e, data, idx, idy) => {
        const { name, value } = e.target;
        
        updateFieldValues(idx, name, value, data);
    };

    const checkBoxhandler = async (selectedOptions, data, idx, idy) => {
        
        let name = data.question;
        let selectedValues = selectedOptions.map((item) => item.title);
        
        repeatedQuestions.repeated[idx] = Object.assign({}, repeatedQuestions.repeated[idx], {
            [name]: selectedValues
        });
        
        setRepeatedQuestions(repeatedQuestions);
        setChildAnswerstoParent(repeatedQuestions, QuestionsData[0].question, index, questionIndex);
    };

    const handleRadioChange = (e, data, sectionIndex, questionIndex, answerIndex, selectedValue) => {
        const { value } = e.target;

        updateFieldValues(sectionIndex, data.question, value, data);
    };

    const dropdownHandler = async (e, data, idx, idy) => {
        const value = e.hasOwnProperty('value') ? e.value?.toString() : e.target.value;

        const name = e.name;
        updateFieldValues(idx, name, value, data);
    };

    const updateFieldValues = (idx, name, value, data) => {
        name = name ? name : data.question;

        repeatedQuestions.repeated[idx] = Object.assign({}, repeatedQuestions.repeated[idx], {
            [name]:
                value === '' ? value : data.suggested_answers?.length > 0 ? data.suggested_answers[value]?.id : value
        });
        
        setRepeatedQuestions(repeatedQuestions);
        setChildAnswerstoParent(repeatedQuestions, QuestionsData[0].question, index, questionIndex);
    };

    const handleDateChange = (idx, idy, date, state, data) => {

        let customDate = '';
        if (date) {
            var date2 = new Date(date),
                mnth = ('0' + (date2.getMonth() + 1)).slice(-2),
                day = ('0' + date2.getDate()).slice(-2);
            customDate = [date2.getFullYear(), mnth, day].join('/');
        } else {
            customDate = '';
        }
        let isValidDate: any = date == 'Invalid Date' ? true : false;
        if (customDate !== 'NaN-aN-aN') {
            let maxStartDate: any = new Date('9998-12-31');
            if (DynamicQuestions[idx]?.questions[idy]?.question === 'Date of Birth') {
                let minStartDate: any = new Date('1900-01-01');
                isValidDate =
                    minStartDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate
                        ? true
                        : false;
            } else {
                let minDefaultDate: any = new Date('1900-01-01');
                isValidDate =
                    minDefaultDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate
                        ? true
                        : false;
            }
        }
        updateFieldValues(idx, data.question, customDate, data);
    };

    const onAdd = () => {
        let list: string[] = generatedQuestions[generatedQuestions.length - 1];
        setGeneratedQuestions([...generatedQuestions, list]);
    };

    const onRemove = async (index) => {
        let list: string[] = generatedQuestions.filter((list, ind) => ind !== index);
        let list1: string[] = repeatedQuestions.repeated.filter(
            (list, ind) => ind !== index
        );
        
        await setGeneratedQuestions([...list]);
        let finalResult = {
            value: repeatedQuestions.value,
            repeated: list1
        };
        
        await setRepeatedQuestions(finalResult);
        await setChildAnswerstoParent(finalResult, QuestionsData[0].question);
    };

    return (
        <React.Fragment>
            {generatedQuestions.length > 0 &&
                generatedQuestions.map((subList: any, index) => (
                    <React.Fragment>
                        <div css={parentFieldRow} key={index}>
                            {subList.map((ques: any, ind) => {
                                return (
                                    <div css={childTwoCol} key={ind}> 
                                        <div>
                                            <label
                                                css={['Reason for modification'].includes(ques.question) ? label2 : childLabel}
                                            >
                                                {ques.question}
                                            </label>
                                            {ques.description && (
                                                <label>
                                                    <small>({ques.description})</small>
                                                </label>
                                            )}{' '}
                                        </div>
                                        <React.Fragment>
                                            {ques.answer_type === 'SELECT' ? (
                                                <Dropdown
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    handleChange={dropdownHandler}
                                                    disableField={false}
                                                    updateLocalState={() => { }}
                                                />
                                            ) : ques.answer_type === 'RADIO' ? (
                                                <Radio
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    handleChange={handleRadioChange}
                                                    updateLocalState={() => { }}
                                                    disableField={false}
                                                />
                                            ) : ques.answer_type === 'CHECKBOX' ? (
                                                <Checkbox
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    handleChange={checkBoxhandler}
                                                    updateLocalState={() => { }}
                                                    disableField={false}
                                                />
                                            ) : ques.answer_type === 'TEXT' ? (
                                                <InputField
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    preventCopyPaste=""
                                                    handleChange={handlerChange}
                                                    updateLocalState={() => { }}
                                                    keyUp={() => { }}
                                                    disableField={false}
                                                    configType={configType}
                                                />
                                            ) : ques.answer_type === 'DATE' ? (
                                                <DateField
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    validateDateFormate={validateDateFormate}
                                                    handleDatePicker={handleDateChange}
                                                    updateLocalState={() => { }}
                                                    DynamicQuestions={state.DynamicQuestions}
                                                    disableField={false}
                                                />
                                            ) : (
                                                <NumberField
                                                    data={ques}
                                                    sectionIndex={index}
                                                    questionIndex={ind}
                                                    state={repeatedQuestions.repeated}
                                                    getAge={Utility.getAge}
                                                    isPredictionScores=""
                                                    handleChange={handlerChange}
                                                    updateLocalState={() => { }}
                                                    keyUp={() => { }}
                                                    disableField={false}
                                                    configType={configType}
                                                />
                                            )}


                                            {isSubmitted ? (
                                                repeatedQuestions.repeated[index] &&
                                                    repeatedQuestions.repeated[index][ques.question] ? (
                                                    ''
                                                ) : ques.required === 'yes' ? (
                                                    <div style={{ color: 'red' }}>Required</div>
                                                ) : (
                                                    ''
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </React.Fragment>
                                    </div>


                                );
                            })}
                           
                        </div>
                         <div style={{
                                marginTop: '-10px',
                                transform: 'rotate(180Deg)'
                            }}>
                                <strong
                                    
                                >
                                    { generatedQuestions.length - 1 === index && <AddCircleSharpIcon onClick={onAdd} /> }
                                    { generatedQuestions.length !== 1 && (
                                        <HighlightOffSharpIcon
                                            onClick={() => onRemove(index)}
                                            style={{ color: 'red' }}
                                        />
                                    )}
                                    
                                </strong>
                                </div>
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
};

export default DynamicGeneratedQuestions;
